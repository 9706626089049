exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-body-slam-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/BodySLAM/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-body-slam-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-classroom-digital-twins-with-instrumentation-free-gaze-tracking-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Classroom_Digital_Twins_with_Instrumentation-Free_Gaze_Tracking/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-classroom-digital-twins-with-instrumentation-free-gaze-tracking-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-controller-pose-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/ControllerPose/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-controller-pose-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-convolutional-neural-networks-for-ocular-smartphone-based-biometrics-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Convolutional_Neural_Networks_for_Ocular_Smartphone-Based_Biometrics/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-convolutional-neural-networks-for-ocular-smartphone-based-biometrics-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-cool-moves-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Cool Moves/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-cool-moves-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-direction-of-voice-do-v-estimation-for-intuitive-speech-interaction-with-smart-devices-ecosystems-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Direction-of-Voice_(DoV)_Estimation_for_Intuitive_Speech_Interaction_with_Smart_Devices_Ecosystems/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-direction-of-voice-do-v-estimation-for-intuitive-speech-interaction-with-smart-devices-ecosystems-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-edu-sense-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/EduSense/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-edu-sense-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-eit-pose-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/EITPose/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-eit-pose-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-eye-center-localization-and-detection-using-radial-mapping-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Eye_center_localization_and_detection_using_radial_mapping/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-eye-center-localization-and-detection-using-radial-mapping-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-eye-mu-interactions-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/EyeMU_Interactions/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-eye-mu-interactions-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-eye-spy-vr-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/EyeSpyVR/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-eye-spy-vr-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-gym-cam-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/GymCam/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-gym-cam-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-imu-poser-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/IMUPoser/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-imu-poser-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-lemur-dx-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/LemurDx/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-lemur-dx-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-light-anchors-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/LightAnchors/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-light-anchors-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-me-cap-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/MeCap/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-me-cap-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-mobile-poser-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/MobilePoser/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-mobile-poser-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-opti-dwell-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/OptiDwell/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-opti-dwell-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-pose-on-the-go-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Pose-on-the-Go/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-pose-on-the-go-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-privacy-mic-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/PrivacyMic/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-privacy-mic-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-rgbd-gaze-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/RGBDGaze/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-rgbd-gaze-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-sa-mo-sa-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/SAMoSA/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-sa-mo-sa-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-scratch-that-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/ScratchThat/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-scratch-that-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-touch-pose-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/TouchPose/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-touch-pose-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-tribo-touch-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/TriboTouch/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-tribo-touch-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-ubicoustics-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Ubicoustics/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-ubicoustics-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-vid-2-doppler-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/Vid2Doppler/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-vid-2-doppler-index-mdx" */),
  "component---src-templates-project-template-js-content-file-path-src-content-projects-xr-objects-index-mdx": () => import("./../../../src/templates/projectTemplate.js?__contentFilePath=/home/runner/work/spice-lab.org/spice-lab.org/src/content/projects/XR-Objects/index.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-src-content-projects-xr-objects-index-mdx" */)
}

